<template>
  <div
    class="deck-radio"
    :class="classes"
  >
    <v-radio-group
      v-model="computedValue"
      hide-details
      :density="dense ? 'compact' : 'default'"
      v-bind="$attrs"
    >
      <v-radio
        :value="0"
        :disabled="disabled"
        :label="label"
        :aria-label="ariaLabel"
        class="ma-0 pa-0 deck-radio__radio"
        true-icon="fa-circle-small fa-solid"
        false-icon=""
        color="primary"
        v-bind="$attrs"
      >
        <template
          v-if="$slots.default"
          #label
        >
          <slot>
            <deck-label
              :text="label"
              size="small"
            />
          </slot>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>

/**
 * Wrapper component for v-radio Note: This component currently only for visual
 * purposes. Avoid using it for radio toggles with no external manual logic to
 * handle the state. This should only be used for visual purposes of
 * representing a single-select UI Pattern.
 * TODO: This is super barebones and on the verge of being unusable. Need to
 * properly implement it alongside a deck-radio-group component with
 * v-radio-group to control the state.
 */
export default {
  name: 'DeckRadio',
  components: {
    DeckLabel: defineAsyncComponent(() => import('~/deck/label')),
  },
  props: {
    /**
     * The modelValue of the radio.
     * @type {boolean}
     * @default undefined
     */
    modelValue: {
      type: Boolean,
      default: undefined,
    },

    /**
     * Disable the radio.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The label to display alongside the radio.
     * @type {string}
     * @default undefined
     */
    label: {
      type: String,
      default: undefined,
    },

    /**
     * The aria-label html attribute.
     * @type {string}
     * @default undefined
     */
    ariaLabel: {
      type: String,
      default: undefined,
    },

    /**
     * Renders the radio in a smaller size.
     * @type {boolean}
     * @default false
     */
    dense: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    classes() {
      return {
        'deck-radio--checked': this.modelValue,
        'deck-radio--unchecked': !this.modelValue,
        'deck-radio--disabled': this.disabled,
      };
    },
    computedValue: {
      get() {
        return this.modelValue ? 0 : null;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue === 0);
      },
    },
  },
};
</script>
<style lang="scss">
.deck-radio {
  --deck-radio-label-line-height: var(--z-line-height-base);
  --deck-radio-box-height: 16px;

  z-index: 0;

  .v-input--selection-controls {
    margin: 0 !important;
    padding: 0 !important;

    .v-input__control.v-input__control {
      min-height: var(--deck-radio-box-height) !important;

    .deck-radio__radio {
      align-items: flex-start;
      gap: 8px;
      padding: 0 !important;
      min-height: unset !important;
      border-color: transparent !important;
      border-radius: 0 !important;
      background-color: transparent !important;

      .v-input--selection-controls__input {
        margin-right: 0;
        height: var(--deck-radio-box-height);
        width: var(--deck-radio-box-height);

        .v-icon {
          font-size: 12px;
          transition: 100ms ease;
          pointer-events: none;
          z-index: 2;
          color: #FFFFFF !important;

          &::before {
            font-size: 1em;
          }
        }

        input {
          appearance: none;
          opacity: 1 !important;
          background-color: #FFFFFF;
          border: 1px solid var(--z-color-text);
          border-radius: var(--z-border-radius-circle);
          pointer-events: none;
          z-index: 1;
        }
      }

      .v-label {
        line-height: var(--deck-radio-label-line-height);

        // Centers the first line of the label with the radio box
        transform: translateY(calc(var(--deck-radio-box-height) / 2 - 0.5em * var(--deck-radio-label-line-height)))
      }

      .v-input--selection-controls__ripple {
        position: absolute;
        width: 28px;
        height: 28px;
        border-radius: var(--z-border-radius-inner-base);
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        margin: 0;

        &::before, .v-ripple__container {
          transform: scale(1) !important;
        }

        &::before {
          transition: 100ms ease !important;
        }
      }
    }
  }
}

  .v-input:hover .v-input--selection-controls__ripple::before {
    background: currentColor;
  }
}

.deck-radio__radio {
  color: rgb(var(--v-theme-primary)) !important;
}

.deck-radio--unchecked .v-icon {
  opacity: 0;
}

.deck-radio--checked input {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.deck-radio--disabled .v-input {
  pointer-events: none;
  opacity: 0.5;
}
</style>
